export class Menu {
  constructor(el) {
    this.el = el;
    this.withSub = this.el.querySelectorAll(".with-sub-link");
    this.menuBar = this.el.querySelector(".main-bar");
    this.button = this.el.querySelector("#menu-button");
    this.menuWrapper = this.el.querySelector(".menu-wrapper");
    this.active = this.el.querySelector(".menu-link.text-tomato");
    this.contentMain = document.querySelector(".content-main");
    this.current = null;
  }

  init = () => {
    const path = window.location.pathname;
    this.current = this.el.querySelector(
      `a.menu-link[href*="${path}"], a.sub-menu-link[href*="${path}"]`
    );

    this.withSub.forEach((withSub) => (withSub.onclick = this.toggleSub));
    this.button.onclick = this.menuButtonHandler;
  };

  toggleSub = (e) => {
    this.current = e.currentTarget;
    if (this.current.parentElement.classList.contains("active")) {
      this.closeSub(this.current);
    } else {
      this.openSub(this.current);
    }
  };

  closeSub = (current) => {
    current.parentElement.classList.remove("active");
    this.menuBar.classList.remove("xl:!mt-18");
    this.contentMain.classList.remove("xl:pt-18");
    current.classList.remove("text-tomato");
    const sub = current.parentElement.querySelector(".sub-menu");
    if (sub) sub.classList.remove("!h-auto", "xl:!h-18");

    if (this.active) this.active.classList.add("text-tomato");
  };

  openSub = (current) => {
    this.active = current;
    current.parentElement.classList.add("active");
    this.menuBar.classList.add("xl:!mt-18");
    this.contentMain.classList.add("xl:pt-18");
    current.parentElement
      .querySelector(".sub-menu")
      .classList.add("!h-auto", "xl:!h-18");
    current.classList.add("text-tomato");
    this.el.querySelectorAll(".menu-link").forEach((el) => {
      if (el !== current) {
        const sub = el.parentElement.querySelector(".sub-menu");
        if (sub) sub.classList.remove("!h-auto", "xl:!h-18");
        el.classList.remove("text-tomato");
        el.parentElement.classList.remove("active");
      }
    });
  };

  menuButtonHandler = () => {
    this.button.querySelector(".open-icon").classList.toggle("hidden");
    this.button.querySelector(".close-icon").classList.toggle("hidden");
    this.menuWrapper.classList.toggle("translate-x-full");
  };

  close = () => {
    this.button.querySelector(".open-icon").classList.remove("hidden");
    this.button.querySelector(".close-icon").classList.add("hidden");
    this.menuWrapper.classList.add("translate-x-full");
  };

  setActiveGlobal = () => {
    const path = window.location.pathname;
    const currentLink = this.el.querySelector(
      `a.menu-link[href*="${path}"], a.sub-menu-link[href*="${path}"]`
    );

    this.el
      .querySelectorAll(`.menu-link, .sub-menu-link, .with-sub`)
      .forEach((link) => {
        link.classList.remove("active", "text-tomato");
      });

    if (currentLink && path !== "/") {
      const currentWrapper = currentLink.parentElement.parentElement;
      const currentMenuLink =
        currentWrapper.parentElement.querySelector(".menu-link");
      if (currentLink.classList.contains("sub-menu-link")) {
        this.closeSub(this.active);
        if (this.active === currentMenuLink) {
          currentMenuLink.classList.add("text-tomato");
        }
        currentLink.classList.add("text-tomato");
        this.current = currentLink;
      } else {
        if (this.active && this.active.classList.contains("with-sub-link")) {
          const link = this.active;
          this.active = currentLink;
          this.closeSub(link);
        }
        currentLink.classList.add("text-tomato");
        this.current = this.active = currentLink;
      }
    } else {
      if (this.active && this.active.classList.contains("with-sub-link")) {
        const link = this.active;
        this.active = null;
        this.closeSub(link);
      }
    }
  };
}
